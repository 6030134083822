<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header" footer-tag="footer">
      <b-nav tabs>
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item active v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <b-card bg-variant="primary" style="margin-bottom: 5px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Veri Yapısı Analizleri
          <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
        </b-card-header>
        <b-row style="padding: 3px;" v-if="d_showMode">
          <b-col sm="12" md="12">
            <b-card class="card-accent-secondary" no-body style="color: black; height: 100%; margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Analiz İşlemleri
              </b-card-header>
              <b-row style="padding: 3px;">
                <b-col sm="12" lg="6">
                  <b-form-select v-model="d_selectedAnalyzeIndex">
                    <option v-for="(analyze, analyze_ind) in d_analyzeList" :value="analyze_ind">{{ analyze.label }}</option>
                  </b-form-select>
                  <b-form-checkbox v-model="d_analyzeStructure.download"> Analiz Çıktılarını İndir</b-form-checkbox>
                </b-col>
                <b-col sm="12" lg="4">
                  <i v-if="d_selectedAnalyzeIndex !== ''">
                    {{ d_analyzeList[d_selectedAnalyzeIndex].info }}
                  </i>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button size="md" variant="warning" @click="f_analyzeStructure()"><i class="fa fa-update"></i>analiz yap</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card style="margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Analiz sonucu
        </b-card-header>
        <b-row style="margin: 0px;">
          <b-col sm="12" md="12">
            <div style="overflow-y: auto; height: 300px; white-space: pre;">
              {{ f_getJsonStringify(d_analyzeResult) }}
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import AdministratorService from '@/services/administrator';
import FileSaver from 'file-saver';

export default {
  name: 'DataStructureAnalyze',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  data () {
    return {
      d_analyzeResult: '',
      d_selectedAnalyzeIndex: '',
      d_analyzeList: [{
        'label': 'Duplike hastaları analiz et',
        'value': 'only_analyze',
        'info': 'Hatalı olarak birden fazla yapılmış kayıtları analiz eder.',
        'service': 'analyze_and_merge_duplicated_patients'
      }, {
        'label': 'Duplike hastaları analiz et ve birleştir',
        'value': 'analyze_and_merge',
        'info': 'Hatalı olarak birden fazla yapılmış kayıtları analiz eder. Ve tespit edilen duplikasyonlardaki wdmrlar gibi dataları tek bir vaka olarak yeniden düzenler',
        'service': 'analyze_and_merge_duplicated_patients'
      }, {
        'label': 'Hastaya Kayıtlı Wdmlerdeki boş olarak kaydedilmiş parametrelerin analiz edilmesi',
        'value': 'only_analyze',
        'info': 'Hastalara ait kayıtlı wdmrlarda dolu olmadığı halde veri yapısında val alanı boş olarak kalmış parametreleri listeler. Çıktı olarak hastane grubu, hastane, hasta bilgileri (isim, soyisim, id listesi), wdmr key listesi ve parametre listesini verir. ',
        'service': 'analyze_patient_wdmr_errors'
      }, {
        'label': 'Hastaya Kayıtlı Wdmlerdeki boş olarak kaydedilmiş parametrelerin analiz edilmesi ve mümkün olanların tamiri',
        'value': 'analyze_and_fix',
        'info': 'Hastalara ait kayıtlı wdmrlarda dolu olmadığı halde veri yapısında val alanı boş olarak kalmış parametreleri listeler. Çıktı olarak hastane grubu, hastane, hasta bilgileri (isim, soyisim, id listesi), wdmr key listesi ve parametre listesini verir. ',
        'service': 'analyze_patient_wdmr_errors'
      }],
      d_analyzeStructure: {
        'download': false
      },
      user: {},
      d_showMode: true
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_getSearchList();
      }
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_analyzeStructure: function () {
      let data = {};
      if (this.d_selectedAnalyzeIndex !== '') {
        let analyze_data = this.d_analyzeList[this.d_selectedAnalyzeIndex];
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizleriniz yapılıyor. Lütfen bekleyiniz.' } });
        if (analyze_data.service === 'analyze_and_merge_duplicated_patients') {
          data.type = analyze_data.value;
          // console.log(data);
          AdministratorService.analyze_and_merge_duplicated_patients(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                this.d_analyzeResult = resp.data.result;
                if (this.d_analyzeStructure.download && resp.data.result) {
                  let json_file = JSON.stringify(resp.data.result, null, 4);
                  let download_data = [json_file];
                  var blob = new Blob(download_data, { type: "text/plain;charset=utf-8" });
                  FileSaver.saveAs(blob, "analyze_and_merge_duplicated_patients.json");
                }
                this.showNotification({ 'title': 'Sistem Mesajı', 'message': 'işlem tamamlandı', 'type': 'warn', 'timeout': 5000 });
              } else {
                this.showNotification({ 'title': 'Sistem Mesajı', 'message': resp.data.message, 'type': 'warn', 'timeout': 5000 });
                alert('error: ' + resp.data.message);
              }
            });
        } else if (analyze_data.service === 'analyze_patient_wdmr_errors') {
          data.type = analyze_data.value;
          AdministratorService.analyze_patient_wdmr_errors(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                this.d_analyzeResult = resp.data.result;
                if (this.d_analyzeStructure.download && resp.data.result) {
                  let json_file = JSON.stringify(resp.data.result, null, 4);
                  let download_data = [json_file];
                  var blob = new Blob(download_data, { type: "text/plain;charset=utf-8" });
                  FileSaver.saveAs(blob, "analyze_patient_wdmr_errors.json");
                }
                this.showNotification({ 'title': 'Sistem Mesajı', 'message': 'işlem tamamlandı', 'type': 'warn', 'timeout': 5000 });
              } else {
                this.showNotification({ 'title': 'Sistem Mesajı', 'message': resp.data.message, 'type': 'warn', 'timeout': 5000 });
                alert('error: ' + resp.data.message);
              }
            });
        }
      } else {
        alert('Lütfen analiz tipi seçiniz');
      }
    }
  },
  components: {},
  watch: {}
};

</script>

<style type="text/css">

</style>

