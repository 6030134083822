import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  analyze_and_merge_duplicated_patients (data) {
    return Vue.http.post(API_BASE_URL + 'analyze_and_merge_duplicated_patients', data);
  },
  analyze_patient_wdmr_errors (data) {
    return Vue.http.post(API_BASE_URL + 'analyze_patient_wdmr_errors', data);
  },
  create_wdm248_for_wisdom () {
    return Vue.http.post(API_BASE_URL + 'create_wdm248_for_wisdom');
  }
};
